import { Divider } from './divider'
import { Values } from './values'
export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <Divider />
        <div className='ui-container'>
        <p id="contactQuote" className="quotes">&quot;Our commitment to perfection goes just beyond our product, in fact our commitment to perfection lies in our excellent ability to serve our clients with integrity, transparency, trust &amp; wisdom. &quot;</p>
        <p id="contactTitle" className="title grow">Our Purpose</p>
       <div className="title-divider"></div>
        <div id="contactValues" className='row'>
               <Values />
        </div>
        <div  id="contactDetails">
          <div className='col-md-4 contact-info'>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                <p className="addressFont"> {props.data ? props.data.addressLine1 : 'loading'} </p>
                <p className="addressFont"> {props.data ? props.data.addressLine2 : 'loading'} 
               {props.data ? props.data.addressLine3 : 'loading'}
               {props.data ? props.data.area : 'loading'}</p>
               <p className="addressFont"> {props.data ? props.data.city : 'loading'}
               {props.data ? props.data.state : 'loading'}
               {props.data ? props.data.country : 'loading'}
              </p>
           
           </p> </div>
            </div>
            <div className='col-md-4 contact-info'>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>
                <p className="addressFont">  <a data-testid="linkElement" href="mailto:info@vvjeweldiam.com?subject=V and V JewelDiam Enquiry" target="_blank" className="enquiry">{props.data ? props.data.primaryEmail : 'loading'}</a> </p>
                <p className="addressFont">  <a data-testid="linkElement" href="mailto:pranay@vvjeweldiam.com?subject=V and V JewelDiam Enquiry" target="_blank" className="enquiry">{props.data ? props.data.secondaryEmail : 'loading'}</a> </p>
              </p>
            </div>


            <div className='contact-item'>
              <p>
              <span>
                  <i className='fa fa-phone'></i> Phone
                </span>
                <p className="addressFont"><i className='fa fa-phone'></i> <a data-testid="linkElement" href="tel:+918097648599" target="_blank" className="enquiry"> {props.data ? props.data.phone : 'loading'} </a> </p>
                <p className="addressFont"><i className='fa fa-phone'></i> <a data-testid="linkElement" href="tel:+886910029995" target="_blank" className="enquiry"> {props.data ? props.data.whatsappNo : 'loading'} </a> </p>
              </p>
            </div>
          
            </div>
            
          <div className='col-md-4 contact-info'>
            
            <div className='contact-item'>
              <p>
              <span>
                  <i className='fa fa-handshake-o'></i> Ping Us on
                </span>
                <p className="addressFont"> <i className='fa fa-whatsapp'></i> <a data-testid="linkElement" href="https://wa.me/+918097648599" target="_blank" className="enquiry">{props.data ? props.data.phone : 'loading'} </a> </p>
                <p className="addressFont"><i className='fa fa-phone'></i> <a data-testid="linkElement" href="https://line.me/ti/p/foram06" target="_blank" className="enquiry"> Line {props.data ? props.data.whatsappNo : 'loading'} </a> </p>
                <p className="addressFont"><i className='fa fa-phone'></i> <a data-testid="linkElement" href="weixin://dl/chat?{toID}" target="_blank" className="enquiry"> wechat {props.data ? props.data.whatsappNo : 'loading'} </a> </p>
               
              </p>
            </div>
          </div>

    
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='ui-container text-center'>
          <p>
            &copy; 2022   {props.data ? props.data.companyName : 'Loading'}
          </p>
        </div>
      </div>
    </div>
  )
}
